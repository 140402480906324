import React, { Suspense, lazy, useEffect } from 'react';
import './shared/styles/variables.css'; // Importar el archivo de variables globalmente
import './styles/chatBot.scss'; // Estilos generales del chatbot

// Leer las rutas del archivo .env
const context = process.env.REACT_APP_CONTEXT ?? 'default';
const componentPath = process.env.REACT_APP_COMPONENT_PATH;

let ChatBotComponent : any;

if (componentPath) {
  try {
    ChatBotComponent = lazy(() => import(`${componentPath}`));
  } catch (error) {
    console.error(`Error al importar el componente del contexto: ${context}. Detalles:`, error);
  }
}

const App = () => {
  useEffect(() => {
    document.documentElement.setAttribute('data-context', context); // Asegurarse de aplicar el contexto en el documento HTML
  }, []);

  if (!ChatBotComponent) {
    return <div>Error: Contexto no encontrado.</div>;
  }

  return (
    <Suspense fallback={<div>Cargando...</div>}>
      <div className="App" data-context={context}>
        <ChatBotComponent />
      </div>
    </Suspense>
  );
};

export default App;
